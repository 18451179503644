/* eslint-disable max-statements, max-lines-per-function */
import { handleSliderChange } from '@deltasierra/react/components/core';
import { useResizeObserver, UseResizeObserverHandler } from '@deltasierra/react/hooks/common';
import { ReactEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { ASPECT_RATIOS } from '../constants';
import { AssetEditState, Bounds, Dimensions, OriginalAssetDimensions, Offset, RatioName } from '../types';

const CROPPER_CANVAS_GAP = 0.9;
const DEFAULT_SCALE = 1;
const DEFAULT_ROTATION = 0;

type CanvasDimensions = Dimensions & {
    gappedHeight: number;
    gappedWidth: number;
};

type UseAssetEditOptions = {
    containerRef: AssetEditState['containerRef'];
    draggableAssetRef: AssetEditState['draggableAssetRef'];
    validateAssetIsWithinCropBounds: () => void;
};

type UseAssetEditReturns = Omit<
    AssetEditState,
    'backendResultImageOptions' | 'containerRef' | 'cropperRef' | 'draggableAssetRef'
>;

export function useAssetEdit({
    containerRef,
    draggableAssetRef,
    validateAssetIsWithinCropBounds,
}: UseAssetEditOptions): UseAssetEditReturns {
    const [assetOffset, setAssetOffset] = useState<Offset | null>(null);
    const [assetRotation, setAssetRotation] = useState(DEFAULT_ROTATION);
    const [assetScale, setAssetScale] = useState(DEFAULT_SCALE);
    const [cropRatio, setCropRatio] = useState<RatioName>('Original');
    const [canvasDimensions, setCanvasDimensions] = useState<CanvasDimensions | null>(null);
    const [isAssetLoaded, setIsAssetLoaded] = useState(false);
    const [shouldResetAssetPosition, setShouldResetAssetPosition] = useState(false);
    const [originalAssetDimensions, setOriginalAssetDimensions] = useState<OriginalAssetDimensions | null>(null);

    const handleChangeCropRatio = (ratio: RatioName) => setCropRatio(ratio);
    const handleAssetRotation = handleSliderChange(setAssetRotation);
    const handleAssetScale = handleSliderChange(setAssetScale);
    const handleResetAssetRotation = (value: number) => setAssetRotation(value);
    const handleResetAssetScale = (value: number) => setAssetScale(value);
    const handleFlipRotation = ({ max, min, value }: { value: number; min: number; max: number }) => {
        const newRotation = assetRotation + value;
        if (newRotation >= max) {
            setAssetRotation(max);
        } else if (newRotation <= min) {
            setAssetRotation(min);
        } else {
            setAssetRotation(newRotation);
        }

        validateAssetIsWithinCropBounds();
    };

    // This is called onLoad of the asset in order to trigger a resize to the canvas
    const handleAssetLoad = useCallback<ReactEventHandler<HTMLImageElement>>(event => {
        const assetElement = event.currentTarget;
        setShouldResetAssetPosition(true);
        // On the load of the asset, get the original dimensions
        setOriginalAssetDimensions({
            height: assetElement.height,
            sourceHeight: assetElement.naturalHeight,
            sourceWidth: assetElement.naturalWidth,
            width: assetElement.width,
        });
    }, []);

    const cropperBounds = useMemo((): Bounds | null => {
        if (!canvasDimensions || !originalAssetDimensions) {
            return null;
        }

        let cropperDimensions: Dimensions | null = null;

        // We should initially get the original cropper ratio to be what ever the base asset is
        if (cropRatio === 'Original') {
            if (
                originalAssetDimensions.sourceWidth < canvasDimensions.width * CROPPER_CANVAS_GAP &&
                originalAssetDimensions.sourceHeight < canvasDimensions.height * CROPPER_CANVAS_GAP
            ) {
                cropperDimensions = {
                    height: originalAssetDimensions.sourceHeight,
                    width: originalAssetDimensions.sourceWidth,
                };
            }
        }

        if (!cropperDimensions) {
            const canvasAspectRatio = canvasDimensions.width / canvasDimensions.height;
            const cropAspectRatio =
                cropRatio === 'Original'
                    ? originalAssetDimensions.sourceWidth / originalAssetDimensions.sourceHeight
                    : ASPECT_RATIOS[cropRatio];

            if (cropAspectRatio > canvasAspectRatio) {
                // Match width, adjust height based on aspect ratio
                // The CROPPER_CANVAS_GAP leaves a little gap between the Canvas we have and the cropper
                const cropperBase = canvasDimensions.gappedWidth;
                cropperDimensions = {
                    height: cropperBase / cropAspectRatio,
                    width: cropperBase,
                };
            } else {
                const cropperBase = canvasDimensions.gappedHeight;
                cropperDimensions = {
                    height: cropperBase,
                    width: cropperBase * cropAspectRatio,
                };
            }
        }

        const left = (canvasDimensions.width - cropperDimensions.width) / 2;
        const right = (canvasDimensions.width + cropperDimensions.width) / 2;
        const bottom = (canvasDimensions.height + cropperDimensions.height) / 2;
        const top = (canvasDimensions.height - cropperDimensions.height) / 2;

        return {
            bottom,
            height: cropperDimensions.height,
            left,
            right,
            top,
            width: cropperDimensions.width,
        };
    }, [originalAssetDimensions, canvasDimensions, cropRatio]);

    useEffect(() => {
        const draggableAsset = draggableAssetRef.current;
        if (!shouldResetAssetPosition || !canvasDimensions || !draggableAsset || !cropperBounds) {
            return;
        }
        setAssetOffset({
            x: canvasDimensions.width / 2 - draggableAsset.width / 2,
            y: canvasDimensions.height / 2 - draggableAsset.height / 2,
        });
        setAssetScale(cropperBounds.width / draggableAsset.width);
        setIsAssetLoaded(true);
        setShouldResetAssetPosition(false);
    }, [canvasDimensions, cropperBounds, draggableAssetRef, shouldResetAssetPosition]);

    // Listen to see when the DOM is resized
    // We can grab the new container offsetWidth and offsetHeight in and set the new setCanvasWidth and setCanvasHeight
    // Now the cropper should resize to be the correct ratio to the new screen size
    const onResize = useCallback<UseResizeObserverHandler>(container => {
        setCanvasDimensions({
            gappedHeight: container.offsetHeight * CROPPER_CANVAS_GAP,
            gappedWidth: container.offsetWidth * CROPPER_CANVAS_GAP,
            height: container.offsetHeight,
            width: container.offsetWidth,
        });
    }, []);

    useResizeObserver({
        element: containerRef.current,
        onResize,
    });

    useEffect(() => {
        validateAssetIsWithinCropBounds();
    }, [assetRotation, assetOffset, validateAssetIsWithinCropBounds]);

    return {
        assetOffset,
        assetRotation,
        assetScale,
        cropRatio,
        cropperBounds,
        handleAssetLoad,
        handleAssetRotation,
        handleAssetScale,
        handleChangeCropRatio,
        handleFlipRotation,
        handleResetAssetRotation,
        handleResetAssetScale,
        isAssetLoaded,
        originalAssetDimensions,
        setAssetOffset,
    };
}
