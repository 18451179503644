import { useMutation } from '@apollo/client';
import {
    ImageCropResizeTarget,
    ImageOptions,
    VALIDATE_IMAGE_CROP_RESIZE,
    ValidateImageCropResizeMutation,
    ValidateImageCropResizeMutationVariables,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { captureException } from '@sentry/nextjs';
import { useCallback, useMemo, useState } from 'react';
import { ASPECT_RATIOS } from '../constants';
import { AssetEditState, MediaItem, OnCroppedAssetSelected } from '../types';

const MAX_WIDTH = 600;

export type UseAssetEditBackendProps = AlertContextType &
    Pick<
        AssetEditState,
        'assetOffset' | 'assetRotation' | 'assetScale' | 'cropperBounds' | 'cropRatio' | 'originalAssetDimensions'
    > & {
        assetId: string;
        assetType: MediaItem['__typename'];
        onAssetSelected: OnCroppedAssetSelected;
        t: Translations<'AssetEdit'>;
    };

type useAssetEditBackendResult = {
    backendResultImageOptions: AssetEditState['backendResultImageOptions'];
    isLoadingBackend: boolean;
    onClickConfirmCropResize: () => void;
};

export function useAssetEditBackend({
    assetId,
    assetOffset,
    assetRotation,
    assetScale,
    assetType,
    cropperBounds,
    cropRatio,
    onAssetSelected,
    originalAssetDimensions,
    showAlert,
    t,
}: UseAssetEditBackendProps): useAssetEditBackendResult {
    const [isLoadingBackend, setIsLoadingBackend] = useState(false);
    const [validateImageCropResize] = useMutation<
        ValidateImageCropResizeMutation,
        ValidateImageCropResizeMutationVariables
    >(VALIDATE_IMAGE_CROP_RESIZE);

    const backendResultImageOptions = useMemo<ImageOptions | null>(() => {
        if (!assetOffset || !cropperBounds || !originalAssetDimensions) {
            return null;
        }

        const scaleMultiplier =
            cropRatio === 'Original' ? cropperBounds.width / cropperBounds.height : ASPECT_RATIOS[cropRatio];

        const height = Math.round(MAX_WIDTH / scaleMultiplier);
        const width = MAX_WIDTH;

        const cropperCenterX = cropperBounds.left + cropperBounds.width / 2;
        const cropperCenterY = cropperBounds.top + cropperBounds.height / 2;

        const assetCenterX = assetOffset.x + originalAssetDimensions.width / 2;
        const assetCenterY = assetOffset.y + originalAssetDimensions.height / 2;

        const relativeXOffset = assetCenterX - cropperCenterX;
        const relativeYOffset = assetCenterY - cropperCenterY;

        const baseScale = cropperBounds.width / originalAssetDimensions.width;

        const scalingFactor = (originalAssetDimensions.sourceWidth / width) * baseScale;

        const imageScale = assetScale / scalingFactor;

        const offsetScale =
            cropperBounds.width / Math.round(originalAssetDimensions.sourceWidth * (baseScale / scalingFactor));

        return {
            height,
            imageOffsetX: relativeXOffset / offsetScale,
            imageOffsetY: -(relativeYOffset / offsetScale),
            imageRotation: assetRotation,
            imageScale,
            width,
        };
    }, [assetOffset, assetRotation, assetScale, cropRatio, cropperBounds, originalAssetDimensions]);

    const onClickConfirmCropResize = useCallback(async () => {
        try {
            if (!backendResultImageOptions) {
                throw new Error('Position could not be calculated');
            }
            setIsLoadingBackend(true);

            const sourceImage =
                assetType === 'Upload'
                    ? {
                          uploadId: assetId,
                      }
                    : { assetId };

            const { data } = await validateImageCropResize({
                variables: {
                    input: {
                        options: backendResultImageOptions,
                        sourceImage,
                        target: ImageCropResizeTarget.ForEmail,
                    },
                },
            });
            if (data?.validateImageCropResize.__typename !== 'ValidateImageCropResizeSuccess') {
                throw new Error(data?.validateImageCropResize.message);
            }
            const asset = data.validateImageCropResize.uploadV2.source;
            onAssetSelected({ uploadId: asset.id, url: data.validateImageCropResize.uploadV2.url });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            captureException(error);
            showAlert('error', t('Image could not be processed'), t('Please try again'));
        } finally {
            setIsLoadingBackend(false);
        }
    }, [assetId, assetType, backendResultImageOptions, onAssetSelected, showAlert, t, validateImageCropResize]);

    return {
        backendResultImageOptions,
        isLoadingBackend,
        onClickConfirmCropResize,
    };
}
