import { decode as atob } from 'base-64';

/**
 * Converts a data uri string: 'data:image/gif;base64,...' to a Blob
 * Adapted from http://stackoverflow.com/a/16245768/953887
 *
 * @param dataUrl - The data uri
 * @param sliceSize - The slice size (optional)
 * @returns - The blob
 */
export function dataUrlToBlob(dataUrl: string, sliceSize = 512): Blob {
    const splitData = dataUrl.split(',');
    const contentType = splitData[0].split(':')[1].split(';')[0];
    const b64Data = splitData[1];
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
