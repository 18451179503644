/* eslint-disable max-lines-per-function */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Grid } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useMemo } from 'react';
import { AssetEditState, DraggableAssetProps, MediaItem } from './types';

const DEBUG_ASSET_EDIT = false;

function DebugBoxItems({ items }: { items: Array<[number | string, number | string]> }): JSX.Element {
    return (
        <>
            {items.map(([key, info]) => (
                <Grid container key={key} spacing={2}>
                    <Grid item xs>
                        {key}
                    </Grid>
                    <Grid item>{info}</Grid>
                </Grid>
            ))}
        </>
    );
}
DebugBoxItems.displayName = 'DebugBoxItems';

export type AssetEditCanvasProps = DraggableAssetProps &
    Omit<
        AssetEditState,
        | 'getAssetOffsetPosition'
        | 'handleAssetRotation'
        | 'handleAssetScale'
        | 'handleChangeCropRatio'
        | 'handleFlipRotation'
        | 'handleResetAssetRotation'
        | 'handleResetAssetScale'
        | 'initialAssetScale'
        | 'isCropEnabled'
        | 'originalAssetDimensions'
        | 'setAssetOffset'
    > & {
        asset: MediaItem;
        t: Translations<'AssetEdit'>;
    };

export function AssetEditCanvas({
    asset,
    assetOffset,
    assetRotation,
    assetScale,
    backendResultImageOptions,
    containerRef,
    cropperBounds,
    cropperRef,
    draggableAssetRef,
    handleAssetLoad,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isAssetLoaded,
    isDraggingAsset,
}: AssetEditCanvasProps): JSX.Element {
    const debugInfo: Array<[string, number | string]> = [
        ['Offset', `${assetOffset?.x},${assetOffset?.y}`],
        ['Rotation', assetRotation],
        ['Scale', assetScale],
        [
            'Cropper bounds',
            `${cropperBounds?.left},${cropperBounds?.top},${cropperBounds?.right},${cropperBounds?.bottom}`,
        ],
        ['Cropper dimensions', `${cropperBounds?.width}x${cropperBounds?.height}`],
    ];
    const backendDebugInfo = useMemo(
        () => (backendResultImageOptions ? Object.entries(backendResultImageOptions) : null),
        [backendResultImageOptions],
    );

    return (
        <Box
            ref={containerRef}
            sx={{
                border: 'solid',
                cursor: isDraggingAsset ? 'grabbing' : 'grab',
                flexGrow: 1,
                maxHeight: '800px',
                minHeight: '150px',
                overflow: 'hidden',
                position: 'relative',
            }}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            {/* Cropper - Shadow to all the cropped out area */}
            <Box
                ref={cropperRef}
                sx={{
                    boxShadow: '0 0 0 9999em',
                    boxSizing: 'border-box',
                    color: 'rgba(0, 0, 0, 0.5)',
                    height: cropperBounds && isAssetLoaded ? `${cropperBounds.height}px` : 0,
                    left: cropperBounds && isAssetLoaded ? `${cropperBounds.left}px` : 0,
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: cropperBounds && isAssetLoaded ? `${cropperBounds.top}px` : 0,
                    width: cropperBounds && isAssetLoaded ? `${cropperBounds.width}px` : 0,
                    zIndex: 10,
                }}
            />
            <img
                alt={'title' in asset ? asset.title : 'image'}
                id="canvas-image"
                ref={draggableAssetRef}
                src={'signedUrl' in asset ? asset.signedUrl : asset.url || ''}
                style={{
                    height: 'auto',
                    left: assetOffset ? assetOffset.x : '50%',
                    maxWidth: '100%',
                    position: 'absolute',
                    top: assetOffset ? assetOffset.y : '50%',
                    transform: `
                    scale(${assetScale})
                    rotate(${assetRotation}deg)`,
                    visibility: isAssetLoaded ? 'visible' : 'hidden',
                }}
                onLoad={handleAssetLoad}
            />
            {DEBUG_ASSET_EDIT && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 0, 0, 0.7)',
                        color: 'white',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 9999999999,
                    }}
                >
                    <DebugBoxItems items={debugInfo} />
                </Box>
            )}
            {DEBUG_ASSET_EDIT && backendDebugInfo !== null && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 51, 161, 0.7)',
                        bottom: 0,
                        color: 'white',
                        position: 'absolute',
                        right: 0,
                        zIndex: 9999999999,
                    }}
                >
                    <DebugBoxItems items={backendDebugInfo} />
                </Box>
            )}
        </Box>
    );
}
AssetEditCanvas.displayName = 'AssetEditCanvas';
