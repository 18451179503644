'use client';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
    AssetFileFragmentFragment,
    CreateFileMutation,
    CreateFileMutationVariables,
    GET_UPLOADED_COLLECTION,
    GetUploadedCollectionIdQuery,
    GetUploadedCollectionIdQueryVariables,
    UPLOAD_ASSET_TO_ASSET_LIBRARY,
} from '@deltasierra/frontend/graphql';
import { UseUploadFileUploadResult, useUploadFile } from '@deltasierra/react/hooks/common';
import { assertNever } from '@deltasierra/type-utilities';
import { useCallback, useState } from 'react';

type OnCompleteAssetResult = {
    asset: AssetFileFragmentFragment;
    assetId: string;
    type: 'asset';
    uploadId: string;
    url: string;
};

type OnCompleteUploadResult = {
    sourceFile: File;
    type: 'upload';
    upload: UseUploadFileUploadResult;
};

/**
 * This is the base type for destinations in the asset library
 */
type AssetLibraryDestinationBase = {
    onComplete: (result: OnCompleteAssetResult) => void;
};

type LocationRecentlyAddedDestination = AssetLibraryDestinationBase & {
    locationId: string;
    destination: 'location-default-collection';
};

type FolderOrCollectionIdDestination = AssetLibraryDestinationBase & {
    folderOrCollectionId: string;
    destination: 'collection';
};

/**
 * There is an additional case to upload via the upload tab but not
 * actually create an asset library asset. For this case we need a
 * custom destination. It looks weird but aligns with the business logic.
 */
type UploadOnlyDestination = {
    onComplete: (result: OnCompleteUploadResult) => void;
    destination: 'upload-only';
};

export type AssetLibraryUploadDestination =
    | FolderOrCollectionIdDestination
    | LocationRecentlyAddedDestination
    | UploadOnlyDestination;

export type UploadInput = AssetLibraryUploadDestination & {
    file: File;
    id: string;
    tags?: string[];
};

/**
 * Upload asset to asset library.
 * Error handling toasts should be handled by invoker to avoid passing around translations
 *
 * @returns Upload | error
 */
export function useUploadAssetToLibrary(): {
    uploadFileToAssetLibrary: (upload: UploadInput) => Promise<UseUploadFileUploadResult>;
    uploadingAssets: string[];
} {
    const [uploadingAssets, setUploadingAssets] = useState<string[]>([]);
    const { triggerUpload } = useUploadFile();

    const [uploadAssetToLibrary] = useMutation<CreateFileMutation, CreateFileMutationVariables>(
        UPLOAD_ASSET_TO_ASSET_LIBRARY,
    );
    const [getUploadedLocationCollection] = useLazyQuery<
        GetUploadedCollectionIdQuery,
        GetUploadedCollectionIdQueryVariables
    >(GET_UPLOADED_COLLECTION);

    const getUploadedLocationCollectionId = useCallback(
        async (locationId: string): Promise<string> => {
            const collection = await getUploadedLocationCollection({
                variables: {
                    id: locationId,
                },
            });
            if (!collection.data?.location?.defaultAssetLibraryCollection.id) {
                throw new Error('Location not found or no collections available');
            }
            return collection.data.location.defaultAssetLibraryCollection.id;
        },
        [getUploadedLocationCollection],
    );

    const getFinalUploadStep = useCallback(
        async (input: UploadInput, file: File): Promise<(uploadResult: UseUploadFileUploadResult) => Promise<void>> => {
            switch (input.destination) {
                case 'collection': {
                    throw new Error('Collection upload is currently disabled');
                }
                case 'location-default-collection': {
                    const targetCollectionId = await getUploadedLocationCollectionId(input.locationId);
                    const onComplete = input.onComplete;

                    return async uploadResult => {
                        if (!(uploadResult && uploadResult.url && uploadResult.size)) {
                            throw new Error('Failed to upload file');
                        }
                        // Finally attempt to add the uploaded file to the asset library
                        const upload = await uploadAssetToLibrary({
                            variables: {
                                input: {
                                    folderOrCollectionId: targetCollectionId,
                                    sizeInBytes: uploadResult.size,
                                    tags: input.tags ?? [],
                                    title: uploadResult.filename,
                                    url: uploadResult.url,
                                },
                            },
                        });
                        if (upload.data?.createAssetFile.__typename !== 'AssetFile') {
                            throw new Error('Asset library asset failed to upload');
                        }
                        const { createAssetFile } = upload.data;
                        onComplete({
                            asset: createAssetFile,
                            assetId: createAssetFile.id,
                            type: 'asset',
                            uploadId: uploadResult.id,
                            url: createAssetFile.url,
                        });
                    };
                }
                case 'upload-only': {
                    const onComplete = input.onComplete;
                    // eslint-disable-next-line @typescript-eslint/require-await
                    return async uploadResult => {
                        onComplete({ sourceFile: file, type: 'upload', upload: uploadResult });
                    };
                }
                default:
                    return assertNever(input);
            }
        },
        [getUploadedLocationCollectionId, uploadAssetToLibrary],
    );

    const uploadFileToAssetLibrary = useCallback(
        async (input: UploadInput): Promise<UseUploadFileUploadResult> => {
            try {
                // This is so we can track the uploads in progress
                // We need to assign our own id to the upload
                setUploadingAssets(prev => [...prev, input.id]);

                /**
                 * This is because we have 2 paths, one uploads just the upload file
                 * and the other uploads with the asset library file creation. We create
                 * it asynchronously because sometimes data needs to be loaded first before we
                 * start the upload.
                 */
                const finalUploadStep = await getFinalUploadStep(input, input.file);

                // Upload the file using DS
                const data = await triggerUpload(input.file, 'assetLibrary');

                await finalUploadStep(data);
                return data;
            } finally {
                setUploadingAssets(prevSet => [...prevSet].filter(existingId => existingId !== input.id));
            }
        },
        [getFinalUploadStep, triggerUpload],
    );
    return { uploadFileToAssetLibrary, uploadingAssets };
}
