import { AlertContextType } from '@deltasierra/react/components/core';
import { useTranslations } from '@deltasierra/translations/react';
import { isGifMimeType } from '@deltasierra/utilities/web-image';
import { EmailAllowedFileValidationType } from '../types';

type ValidateFileIsAllowedTypeAndSize = (
    file: File,
    allowedTypes?: string[],
    maxFileSize?: number,
    maxFileSizeGif?: number,
) => EmailAllowedFileValidationType;

// Note: useValidate - Named briefly as hook could export multiple validations
type UseValidateReturns = {
    validateFileIsAllowedTypeAndSize: ValidateFileIsAllowedTypeAndSize;
};
export const useValidate = (showAlert: AlertContextType['showAlert']): UseValidateReturns => {
    const t = useTranslations('AssetLibrary');
    const validateFileIsAllowedTypeAndSize: ValidateFileIsAllowedTypeAndSize = (
        file,
        allowedTypes,
        maxFileSize,
        maxFileSizeGif,
    ) => {
        let message;
        if (isGifMimeType(file.type) && maxFileSizeGif) {
            if (file.size > maxFileSizeGif) {
                message = t('This file is over 2MB and too large to use in an email');
                showAlert('error', message);
                return { isValid: false, message };
            }
        } else if (maxFileSize) {
            if (file.size > maxFileSize) {
                message = t('This file is over 2MB and too large to use in an email');
                showAlert('error', message);
                return { isValid: false, message };
            }
        }
        if (allowedTypes) {
            if (!allowedTypes.includes(file.type)) {
                message = t('This file type is unsupported to use in an email');
                showAlert('error', message);
                return { isValid: false, message };
            }
        }
        return { isValid: true, message: null };
    };
    return {
        validateFileIsAllowedTypeAndSize,
    };
};
