import { useCallback, useState } from 'react';
import { AssetEditState } from '../types';

type UseAssetBoundsValidationProps = Pick<AssetEditState, 'cropperRef' | 'draggableAssetRef'>;

export function useAssetBoundsValidation({ cropperRef, draggableAssetRef }: UseAssetBoundsValidationProps): {
    isCropEnabled: boolean;
    validateAssetIsWithinCropBounds: () => void;
} {
    const [isCropEnabled, setIsCropEnabled] = useState(true);

    const validateAssetIsWithinCropBounds = useCallback(() => {
        if (!draggableAssetRef.current || !cropperRef.current) {
            return;
        }

        const assetBounds = draggableAssetRef.current.getBoundingClientRect();
        const cropperBounds = cropperRef.current.getBoundingClientRect();

        const isAssetWithinBounds =
            assetBounds.x <= cropperBounds.x + cropperBounds.width &&
            assetBounds.x + assetBounds.width >= cropperBounds.x &&
            assetBounds.y <= cropperBounds.y + cropperBounds.height &&
            assetBounds.y + assetBounds.height >= cropperBounds.y;

        setIsCropEnabled(isAssetWithinBounds);
    }, [cropperRef, draggableAssetRef]);

    return {
        isCropEnabled,
        validateAssetIsWithinCropBounds,
    };
}
