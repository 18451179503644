import { MouseEvent, RefObject, useCallback, useState } from 'react';
import { AssetEditState, DraggableAssetProps, DragInfo } from '../types';

type UseDragAssetProps = {
    draggableAssetRef: RefObject<HTMLDivElement>;
    setAssetOffset: AssetEditState['setAssetOffset'];
    validateAssetIsWithinCropBounds: () => void;
};

export function useDragAsset({
    draggableAssetRef,
    setAssetOffset,
    validateAssetIsWithinCropBounds,
}: UseDragAssetProps): DraggableAssetProps {
    const [dragInfo, setDragInfo] = useState<DragInfo>({
        left: 0,
        startX: 0,
        startY: 0,
        top: 0,
    });
    const [isDraggingAsset, setIsDraggingAsset] = useState(false);

    const handleMouseUp = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDraggingAsset(false);
            validateAssetIsWithinCropBounds();
        },
        [validateAssetIsWithinCropBounds],
    );

    const handleMouseDown = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();

            // Getting the mouses initial position on the screen
            const { clientX, clientY } = event;

            const { current: draggableElement } = draggableAssetRef;

            if (!draggableElement) {
                return;
            }

            // Setting initial start position of the Asset of where it currently is placed
            setIsDraggingAsset(true);
            setDragInfo({
                left: draggableElement.offsetLeft, // Offset left within the parent container
                startX: clientX, // Starting X-cords of the mouse
                startY: clientY, // Starting Y-cords of the mouse
                top: draggableElement.offsetTop, // Offset top within the parent container
            });
        },
        [draggableAssetRef],
    );
    const handleMouseMove = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            const { current: draggableElement } = draggableAssetRef;
            if (!isDraggingAsset || !draggableElement) {
                return;
            }
            event.preventDefault();

            // This is the current mouse position
            const { clientX, clientY } = event;

            // Calculate the difference between the current and starting mouse positions
            const delta = {
                x: clientX - dragInfo.startX,
                y: clientY - dragInfo.startY,
            };

            // Calculate the new position of the asset
            const { left, top } = dragInfo;
            setAssetOffset({
                x: left + delta.x,
                y: top + delta.y,
            });
        },

        [isDraggingAsset, dragInfo, draggableAssetRef, setAssetOffset],
    );
    return {
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        isDraggingAsset,
    };
}
