import { Box, Button, Slider, SliderProps, Typography, IconButton } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { Minus, Plus } from '@deltasierra/react/icons';
import { ReactNode } from 'react';

export type AssetEditSliderProps = Required<
    Pick<SliderProps, 'aria-label' | 'defaultValue' | 'max' | 'min' | 'onChange' | 'step' | 'value'>
> & {
    buttonStep: number;
    header: ReactNode;
    onClickButton: (value: number) => void;
    t: Translations<'AssetEdit'>;
};

export const AssetEditSlider = ({
    buttonStep,
    defaultValue,
    header,
    max,
    min,
    onChange,
    onClickButton,
    step,
    t,
    value,
    ...sliderProps
}: AssetEditSliderProps): JSX.Element => (
    <>
        <Box sx={{ height: '2rem' }}>{header}</Box>
        <Slider
            defaultValue={defaultValue}
            max={max}
            min={min}
            step={step}
            value={value}
            onChange={onChange}
            {...sliderProps}
        />
        <Box sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
                aria-label="minus-button"
                onClick={() => {
                    if (typeof value === 'number') {
                        const newValue = value - buttonStep;
                        if (newValue >= min) {
                            onClickButton(newValue);
                        }
                    }
                }}
            >
                <Minus />
            </IconButton>
            <Button
                size="skinny"
                variant="outlined"
                onClick={() => onClickButton(typeof defaultValue === 'number' ? defaultValue : 0)}
            >
                <Typography>{t('Reset')}</Typography>
            </Button>
            <IconButton
                aria-label="plus-button"
                onClick={() => {
                    if (typeof value === 'number') {
                        const newValue = value + buttonStep;
                        if (newValue <= max) {
                            onClickButton(newValue);
                        }
                    }
                }}
            >
                <Plus />
            </IconButton>
        </Box>
    </>
);

AssetEditSlider.displayName = 'AssetEditSlider';
