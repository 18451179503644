/* eslint-disable max-lines-per-function */
'use client';

import { AlertContextType, EMAIL_ALLOWED_MIME_TYPES, FormControlLabelProps } from '@deltasierra/react/components/core';
import { useCallback, useState } from 'react';
import { isGifMimeType } from '@deltasierra/utilities/web-image';
import { GalleryState } from '../enums';
import { SetSelectedAsset } from '../types';
import { useAssetLibraryPermissionModal } from './useAssetLibraryPermissionModal';
import { AssetLibraryUploadDestination, UploadInput, useUploadAssetToLibrary } from './useUploadAssetToLibrary';
import { useValidate } from './useValidate';

const UPLOAD_POSITION_ID = 'useAssetLibraryUpload';

type UseAssetLibraryUploadOptions = {
    enableCropResizeAssetLibraryV2: boolean;
    maxFileSize: number;
    maxFileSizeGif: number;
    onCloseModal: () => void;
    setGalleryState: (value: GalleryState) => void;
    setSelectedAsset: SetSelectedAsset;
    showAlert: AlertContextType['showAlert'];
    uploadDestination: AssetLibraryUploadDestination;
};

export function useAssetLibraryUpload({
    enableCropResizeAssetLibraryV2,
    maxFileSize,
    maxFileSizeGif,
    onCloseModal,
    setGalleryState,
    setSelectedAsset,
    showAlert,
    uploadDestination,
}: UseAssetLibraryUploadOptions): {
    isCheckedConfirmRead: boolean;
    isCheckedNeverAgain: boolean | undefined;
    isLoadingUpload: boolean;
    isOpenPermissionModal: boolean;
    onChangeAssets: (F: File | undefined) => void;
    onChangeConfirmRead: FormControlLabelProps['onChange'];
    onChangeNeverAgain: FormControlLabelProps['onChange'];
    onClickUploadCancel: () => void;
    onClickUploadNext: () => void;
} {
    const [pendingFile, setPendingFile] = useState<File | null>(null);

    const {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isOpenPermissionModal,
        onChangeIsCheckedConfirmRead,
        onChangeIsCheckedNeverAgain,
        onChangeIsOpenPermissionModal,
    } = useAssetLibraryPermissionModal();

    const { uploadFileToAssetLibrary, uploadingAssets } = useUploadAssetToLibrary();

    const { validateFileIsAllowedTypeAndSize } = useValidate(showAlert);

    const validateFile = useCallback(
        (file: File) => {
            const { isValid } = validateFileIsAllowedTypeAndSize(
                file,
                EMAIL_ALLOWED_MIME_TYPES,
                maxFileSize,
                maxFileSizeGif,
            );
            return isValid;
        },
        [validateFileIsAllowedTypeAndSize, maxFileSize, maxFileSizeGif],
    );

    const onProgressUpload = useCallback(
        async (file: File) => {
            try {
                await uploadFileToAssetLibrary({
                    ...uploadDestination,
                    file,
                    id: UPLOAD_POSITION_ID,
                    onComplete: (result: Parameters<UploadInput['onComplete']>[0]) => {
                        if (
                            enableCropResizeAssetLibraryV2 &&
                            !isGifMimeType(result.type === 'asset' ? result.asset.mimeType : result.sourceFile.type)
                        ) {
                            setSelectedAsset(result.type === 'asset' ? result.asset : result.upload);
                            setGalleryState(GalleryState.CROP_RESIZE);
                            return;
                        }
                        // This is typed as any to fix the spread above
                        uploadDestination.onComplete(result as unknown as any);
                        onCloseModal();
                    },
                });
            } finally {
                onChangeIsCheckedConfirmRead(false);
                setPendingFile(null);
            }
        },
        [
            enableCropResizeAssetLibraryV2,
            onChangeIsCheckedConfirmRead,
            onCloseModal,
            setGalleryState,
            setSelectedAsset,
            uploadDestination,
            uploadFileToAssetLibrary,
        ],
    );
    const onChangeAssets = useCallback(
        (file: File | undefined) => {
            if (!file) {
                throw new Error('File not found');
            }
            const isValid = validateFile(file);
            if (!isValid) {
                return;
            }
            if (isCheckedNeverAgain) {
                void onProgressUpload(file);
            } else {
                setPendingFile(file);
                onChangeIsOpenPermissionModal(true);
            }
        },
        [isCheckedNeverAgain, onChangeIsOpenPermissionModal, onProgressUpload, validateFile],
    );

    const onClickUploadCancel = useCallback(() => {
        onChangeIsOpenPermissionModal(false);
        setPendingFile(null);
    }, [onChangeIsOpenPermissionModal]);

    const onClickUploadNext = useCallback(() => {
        if (isCheckedConfirmRead && pendingFile) {
            void onProgressUpload(pendingFile);
            onChangeIsOpenPermissionModal(false);
        }
    }, [isCheckedConfirmRead, onChangeIsOpenPermissionModal, onProgressUpload, pendingFile]);

    return {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isLoadingUpload: uploadingAssets.includes(UPLOAD_POSITION_ID),
        isOpenPermissionModal,
        onChangeAssets,
        onChangeConfirmRead: (_, checked) => onChangeIsCheckedConfirmRead(checked),
        onChangeNeverAgain: (_, checked) => onChangeIsCheckedNeverAgain(checked),
        onClickUploadCancel,
        onClickUploadNext,
    };
}
