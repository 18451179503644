/* eslint-disable sort-keys */
import { RatioName } from '../types';

export const ASPECT_RATIOS: Record<RatioName, number> = {
    Original: 1,
    'Landscape (3:2)': 3 / 2,
    'Portrait (2:3)': 2 / 3,
    'Square (1:1)': 1 / 1,
    'Vertical (9:16)': 9 / 16,
    'Widescreen (16:9)': 16 / 9,
};
