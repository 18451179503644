import { useRef } from 'react';
import { AssetEditState } from '../types';

export function useAssetRefs(): Pick<AssetEditState, 'containerRef' | 'cropperRef' | 'draggableAssetRef'> {
    const containerRef = useRef<HTMLImageElement>(null);
    const draggableAssetRef = useRef<HTMLImageElement>(null);
    const cropperRef = useRef<HTMLImageElement>(null);

    return {
        containerRef,
        cropperRef,
        draggableAssetRef,
    };
}
