import { graphql } from '../../__graphqlTypes';

export const VALIDATE_IMAGE_CROP_RESIZE = graphql(`
    mutation ValidateImageCropResize($input: ValidateImageCropResizeInput!) {
        validateImageCropResize(input: $input) {
            ... on ValidateImageCropResizeSuccess {
                uploadV2 {
                    source {
                        id
                    }
                    url
                }
            }
            ... on ValidateImageCropResizeError {
                code
                message
            }
        }
    }
`);
