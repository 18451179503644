import { AlertContextType, Box, ModalFullPage, Stack, Typography } from '@deltasierra/react/components/core';
import { AssetEditContainer, OnCroppedAssetSelected } from '@deltasierra/react/features/asset-edit';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { useCallback } from 'react';
import { AssetLibraryBrowser, AssetLibraryBrowserProps } from './AssetLibraryBrowser';
import { AssetLibraryHeader } from './AssetLibraryHeader';
import { AssetLibrarySearchAndSort, AssetLibrarySearchAndSortProps } from './AssetLibrarySearchAndSort';
import { AssetLibraryTabs, AssetLibraryTabsProps } from './AssetLibraryTabs';
import { AssetLibraryUploadContainer, AssetLibraryUploadContainerProps } from './AssetLibraryUploadContainer';
import { GalleryState } from './enums';
import { MediaItem, OnAssetSelected } from './types';

export const TAB_BROWSER = 0;
export const TAB_UPLOAD = 1;

export type AssetLibraryProps = AlertContextType &
    AssetLibraryBrowserProps &
    AssetLibraryUploadContainerProps &
    Omit<AssetLibrarySearchAndSortProps, 'onClickClose'> &
    Pick<AssetLibraryTabsProps, 'currentTabIndex' | 'onChangeTabs'> & {
        disableUploadsTab?: boolean;
        onAssetSelected: OnAssetSelected;
        onClickClose: () => void;
        onClickCancelCrop: () => void;
        selectedAsset: MediaItem | null;
    };

// eslint-disable-next-line max-lines-per-function
export function AssetLibrary({
    allowedTypes,
    assets,
    collections,
    currentCollection,
    currentFolders,
    currentTabIndex,
    disableUploadsTab,
    folderActions,
    galleryState,
    isCheckedConfirmRead,
    isCheckedNeverAgain,
    isLoadingAssets,
    isLoadingCollections,
    isLoadingUpload,
    isOpenCollection,
    isOpenPermissionModal,
    maxFileSize,
    maxFileSizeGif,
    onAssetSelected,
    onChangeAssets,
    onChangeConfirmRead,
    onChangeNeverAgain,
    onChangeSearch,
    onChangeTabs,
    onClickBreadcrumb,
    onClickCancelCrop,
    onClickClose,
    onClickHeader,
    onClickSearch,
    onClickSelectCollection,
    onClickSortBy,
    onClickThumbnail,
    onClickUploadCancel,
    onClickUploadNext,
    onClickView,
    search,
    selectedAsset,
    showAlert,
    sortByOptions,
    t,
    viewOptions,
    watchElementRef,
}: AssetLibraryProps): JSX.Element {
    const onCroppedAssetSelected = useCallback<OnCroppedAssetSelected>(
        result => {
            onAssetSelected({
                type: 'upload',
                uploadId: result.uploadId,
                url: result.url,
                useUrl: true,
            });
        },
        [onAssetSelected],
    );
    return (
        <ModalFullPage open onClose={onClickClose}>
            <Box
                sx={{
                    backgroundColor: 'grey.100',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100%',
                    minWidth: '100%',
                }}
            >
                {galleryState === GalleryState.BASE ? (
                    <>
                        <AssetLibraryHeader
                            content={
                                <AssetLibrarySearchAndSort
                                    search={search}
                                    sortByOptions={sortByOptions}
                                    t={t}
                                    viewOptions={viewOptions}
                                    onChangeSearch={onChangeSearch}
                                    onClickSearch={onClickSearch}
                                    onClickSortBy={onClickSortBy}
                                    onClickView={onClickView}
                                />
                            }
                            sx={{ minHeight: '7.5rem' }}
                            t={t}
                            onClickClose={() => onClickClose()}
                        />
                        <AssetLibraryTabs
                            currentTabIndex={currentTabIndex}
                            tabs={[
                                {
                                    tabPanel: (
                                        <AssetLibraryBrowser
                                            allowedTypes={allowedTypes}
                                            assets={assets}
                                            collections={collections}
                                            currentCollection={currentCollection}
                                            currentFolders={currentFolders}
                                            folderActions={folderActions}
                                            galleryState={galleryState}
                                            isLoadingAssets={isLoadingAssets}
                                            isLoadingCollections={isLoadingCollections}
                                            isOpenCollection={isOpenCollection}
                                            maxFileSize={maxFileSize}
                                            maxFileSizeGif={maxFileSizeGif}
                                            t={t}
                                            watchElementRef={watchElementRef}
                                            onClickBreadcrumb={onClickBreadcrumb}
                                            onClickHeader={onClickHeader}
                                            onClickSelectCollection={onClickSelectCollection}
                                            onClickThumbnail={onClickThumbnail}
                                        />
                                    ),
                                    tabText: t('Asset Library'),
                                },
                                disableUploadsTab
                                    ? null
                                    : {
                                          tabPanel: (
                                              <AssetLibraryUploadContainer
                                                  isCheckedConfirmRead={isCheckedConfirmRead}
                                                  isCheckedNeverAgain={isCheckedNeverAgain}
                                                  isLoadingUpload={isLoadingUpload}
                                                  isOpenPermissionModal={isOpenPermissionModal}
                                                  t={t}
                                                  onChangeAssets={onChangeAssets}
                                                  onChangeConfirmRead={onChangeConfirmRead}
                                                  onChangeNeverAgain={onChangeNeverAgain}
                                                  onClickUploadCancel={onClickUploadCancel}
                                                  onClickUploadNext={onClickUploadNext}
                                              />
                                          ),
                                          tabText: t('Upload'),
                                      },
                                // Do not show the Upload Tab if disableUploadsTab is set to true
                            ].filter(isNotNullOrUndefined)}
                            onChangeTabs={onChangeTabs}
                        />
                    </>
                ) : (
                    <Stack sx={{ height: '100%', rowGap: '1rem' }}>
                        <AssetLibraryHeader
                            content={
                                <Typography sx={{ marginTop: '-9px' }} variant="h2">
                                    {t('Crop image')}
                                </Typography>
                            }
                            t={t}
                            onClickClose={onClickClose}
                        />
                        {selectedAsset && (
                            <AssetEditContainer
                                asset={selectedAsset}
                                showAlert={showAlert}
                                onAssetSelected={onCroppedAssetSelected}
                                onClickCancel={onClickCancelCrop}
                            />
                        )}
                    </Stack>
                )}
            </Box>
        </ModalFullPage>
    );
}
AssetLibrary.displayName = 'AssetLibrary';
