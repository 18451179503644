import { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { relayStylePagination } from '@apollo/client/utilities';

const NonIdTypes = ['ClientReports', 'ClientReportsTemplateReport', 'ClientReportsTemplateReportSettings'];

const NonIdTypesMerged = NonIdTypes.reduce((merged, name) => {
    merged[name] = {
        keyFields: false,
        merge: true,
    };
    return merged;
}, {} as TypePolicies);

export const FRONTEND_GRAPHQL_TYPE_POLICIES: TypePolicies = {
    ...NonIdTypesMerged,
    Agency: {
        fields: {
            clients: relayStylePagination(),
        },
    },
    AssetFolder: {
        fields: {
            assets: relayStylePagination(),
        },
    },
    BuilderTemplateCategory: {
        fields: {
            // Separate KeyArgs for the cache for when we are swapping between showOldEmailTemplates
            templates: relayStylePagination(['input']),
        },
    },
    ClientReportsTemplateReport: {
        ...NonIdTypesMerged.ClientReportsTemplateReport,
        fields: {
            data: relayStylePagination(),
        },
    },
    ClubReadyList: {
        keyFields: false,
        merge: false,
    },
    Collection: {
        fields: {
            assets: relayStylePagination(),
        },
    },
    Location: {
        fields: {
            builderTemplateCategoriesConnection: relayStylePagination(),
            templateCategoriesConnection: relayStylePagination(),
            templateDrafts: relayStylePagination(),
            templateVersions: relayStylePagination(),
            templates: relayStylePagination(),
        },
    },
    User: {
        fields: {
            clients: relayStylePagination(['filter', ['title']]),
            locations: relayStylePagination(['filter', ['title']]),
        },
    },
};
