import { Box, Button } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

export type AssetEditFooterProps = {
    isCropEnabled: boolean;
    onClickCancel: () => void;
    onClickCrop: () => void;
    t: Translations<'AssetEdit'>;
};

export const AssetEditFooter = ({
    isCropEnabled,
    onClickCancel,
    onClickCrop,
    t,
}: AssetEditFooterProps): JSX.Element => (
    <Box>
        <Box sx={{ columnGap: '0.5rem', display: 'flex', justifyContent: 'end', paddingTop: '1rem' }} width="100%">
            <Button
                color="error"
                size="large"
                sx={{ height: '3.5rem', padding: '0.5rem 1.375rem', width: '10.7rem' }}
                variant="outlined"
                onClick={onClickCancel}
            >
                {t('Cancel')}
            </Button>
            <Button
                disabled={!isCropEnabled}
                size="large"
                sx={{ height: '3.5rem', paddingX: '0.5rem', width: '10.7rem' }}
                variant="contained"
                onClick={onClickCrop}
            >
                {t('Confirm')}
            </Button>
        </Box>
    </Box>
);

AssetEditFooter.displayName = 'AssetEditFooter';
