import { Box, IconButton, SxProps, Theme } from '@deltasierra/react/components/core';
import { XClose } from '@deltasierra/react/icons';
import { extendSx } from '@deltasierra/react/theme';
import { Translations } from '@deltasierra/translations/react';
import { ReactNode } from 'react';

export type AssetLibraryHeaderProps = {
    content: ReactNode;
    onClickClose: () => void;
    sx?: SxProps<Theme>;
    t: Translations<'AssetLibrary'>;
};

export const AssetLibraryHeader = ({ content, onClickClose, sx, t }: AssetLibraryHeaderProps): JSX.Element => (
    <Box
        sx={extendSx(
            {
                alignItems: 'center',
                columnGap: '3rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '1.5rem',
                paddingX: '1.5rem',
                width: '100%',
            },
            sx,
        )}
    >
        <IconButton
            aria-label={t('Close')}
            sx={{
                color: 'primary.main',
                position: 'absolute',
                right: '0.2rem',
                top: '0.2rem',
            }}
            onClick={onClickClose}
        >
            <XClose />
        </IconButton>
        {content}
    </Box>
);

AssetLibraryHeader.displayName = 'AssetLibraryHeader';
