'use client';

import { AlertContextType } from '@deltasierra/react/components/core';
import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { WatchElementRefType, useInfiniteScroll } from '@deltasierra/react/hooks/common';
import { Translations } from '@deltasierra/translations/react';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { isGifMimeType } from '@deltasierra/utilities/web-image';
import { ModalAsset } from '../contexts';
import { GalleryState } from '../enums';
import { CollectionId, CollectionItem, Folder, FolderAction, ModalCollection, OnAssetSelected } from '../types';
import { useAssetLibraryBrowseState } from './useAssetLibraryBrowseState';
import { useAssetLibraryCollectionOrFolderAssets } from './useAssetLibraryCollectionOrFolderAssets';
import { useAssetLibraryCollections } from './useAssetLibraryCollections';

type UseAssetLibraryBrowseOptions = {
    clientId: string;
    enableCropResizeAssetLibraryV2: boolean;
    galleryState: GalleryState;
    locationId: string | null;
    onAssetSelected: OnAssetSelected;
    onCloseModal: () => void;
    setGalleryState: (value: GalleryState) => void;
    setSelectedAsset: (asset: AssetFileFragmentFragment) => void;
    showAlert: AlertContextType['showAlert'];
    t: Translations<'AssetLibrary'>;
};

// eslint-disable-next-line max-lines-per-function
export function useAssetLibraryBrowse({
    clientId,
    enableCropResizeAssetLibraryV2,
    galleryState,
    locationId,
    onAssetSelected,
    onCloseModal,
    setGalleryState,
    setSelectedAsset,
    showAlert,
    t,
}: UseAssetLibraryBrowseOptions): {
    assets: ModalAsset[];
    collections: ModalCollection[];
    currentCollection: CollectionItem | null;
    currentFolders: Folder[];
    folderActions: FolderAction[];
    isLoadingAssets: boolean;
    isLoadingCollections: boolean;
    isOpenCollection: Set<CollectionId>;
    onChangeSearch: ChangeEventHandler<HTMLInputElement>;
    onClickBreadcrumb: (id?: string) => void;
    onClickHeader: (collectionId: string) => void;
    onClickSearch: () => void;
    onClickSelectCollection: (collection: CollectionItem) => void;
    onClickThumbnail: (item: ModalAsset) => void;
    search: string;
    watchElementRef: WatchElementRefType | false;
} {
    const [state, dispatch] = useAssetLibraryBrowseState();
    const [currentSearchTerm, setCurrentSearchTerm] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    const { collections, isLoadingCollections } = useAssetLibraryCollections({ clientId, locationId, showAlert, t });

    const { assets, handleFetchMoreAssets, hasMoreAssets, isLoadingAssets } = useAssetLibraryCollectionOrFolderAssets({
        currentSelectedFolder: state.currentSelectedFolder,
        searchInputValue,
        showAlert,
        t,
    });

    // On load of new collections
    useEffect(() => {
        // If its still loading, just return
        if (isLoadingCollections) {
            return;
        }
        // If we have loaded and there is no data
        if (!(collections.length > 0)) {
            showAlert('error', t('Asset Library is not available'));
            onCloseModal();
            return;
        }
        // Get the first non null collection
        // This should do client -> data[0], data[1] then location -> data[0], data[1]
        const firstCollection = collections.flatMap(col => col.data).find(isNotNullOrUndefined);
        // And select it if it exists
        if (firstCollection) {
            dispatch({
                action: 'selectCollection',
                collectionId: firstCollection.id,
            });
        }

        // Show the list of modal collections
        dispatch({
            action: 'toggleCollectionList',
            modalCollectionIds: collections.map(col => col.id),
            open: true,
        });
    }, [collections, dispatch, isLoadingCollections, onCloseModal, showAlert, t]);

    const onClickThumbnail = useCallback(
        (item: ModalAsset) => {
            if (item.__typename === 'AssetFolder') {
                dispatch({
                    action: 'selectFolder',
                    folder: item,
                });
                return;
            }

            if (enableCropResizeAssetLibraryV2 && galleryState === GalleryState.BASE && !isGifMimeType(item.mimeType)) {
                setSelectedAsset(item);
                setGalleryState(GalleryState.CROP_RESIZE);
                return;
            }

            onAssetSelected({
                assetId: item.id,
                type: 'asset',
                url: item.url,
                useUrl: false,
            });
            setGalleryState(GalleryState.BASE);
        },
        [dispatch, enableCropResizeAssetLibraryV2, galleryState, setSelectedAsset, onAssetSelected, setGalleryState],
    );
    const onClickHeader = useCallback(
        (id: string) =>
            dispatch({
                action: 'toggleCollectionList',
                modalCollectionIds: [id],
            }),
        [dispatch],
    );
    const onClickSelectCollection = useCallback(
        (collection: CollectionItem) => {
            dispatch({
                action: 'selectCollection',
                collectionId: collection.id,
            });
        },
        [dispatch],
    );
    const onClickBreadcrumb = useCallback(
        (id?: string) => {
            dispatch({
                action: 'clickBreadcrumb',
                folderId: id ?? null,
            });
        },
        [dispatch],
    );
    const handleSetSearchTerm = (searchInput: string) => {
        if (searchInput === '') {
            // If user is clearing the search input, immediately do a search query to return default
            setSearchInputValue(searchInput);
        }
        // In every case set the search term to what was entered
        setCurrentSearchTerm(searchInput);
    };

    const onElementVisible = useCallback(() => {
        if (!isLoadingAssets) {
            handleFetchMoreAssets();
        }
    }, [isLoadingAssets, handleFetchMoreAssets]);

    const { watchElementRef } = useInfiniteScroll(onElementVisible);

    const currentCollection = useMemo(
        () => collections.flatMap(col => col.data).find(col => col.id === state.activeCollectionId) ?? null,
        [collections, state.activeCollectionId],
    );
    const isOpenCollection = useMemo(() => new Set(state.openModalCollectionIds), [state.openModalCollectionIds]);

    return {
        assets,
        collections,
        currentCollection,
        currentFolders: state.folderHistory,
        folderActions: [],
        isLoadingAssets,
        isLoadingCollections,
        isOpenCollection,
        onChangeSearch: (event: ChangeEvent<HTMLInputElement>) => handleSetSearchTerm(event.target.value),
        onClickBreadcrumb,
        onClickHeader,
        onClickSearch: () => setSearchInputValue(currentSearchTerm),
        onClickSelectCollection,
        onClickThumbnail,
        search: currentSearchTerm,
        watchElementRef: hasMoreAssets && watchElementRef,
    };
}
