import { Button } from '@deltasierra/react/components/core';
import {
    Image01,
    RatioLandscape,
    RatioPortrait,
    RatioSquare,
    RatioVertical,
    RatioWidescreen,
} from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { ASPECT_RATIOS } from './constants';
import { AssetEditState, RatioName } from './types';

export type AssetEditAspectButtonsProps = Pick<AssetEditState, 'cropRatio' | 'handleChangeCropRatio'> & {
    t: Translations<'AssetEdit'>;
};

const iconStyle = { width: '30px' };

const BUTTON_MAP: Record<RatioName, JSX.Element> = {
    'Landscape (3:2)': <RatioLandscape style={iconStyle} />,
    Original: <Image01 style={iconStyle} />,
    'Portrait (2:3)': <RatioPortrait style={iconStyle} />,
    'Square (1:1)': <RatioSquare style={iconStyle} />,
    'Vertical (9:16)': <RatioVertical style={iconStyle} />,
    'Widescreen (16:9)': <RatioWidescreen style={iconStyle} />,
};

export const AssetEditAspectButtons = ({
    cropRatio,
    handleChangeCropRatio,
    t,
}: AssetEditAspectButtonsProps): JSX.Element => (
    <>
        {(Object.keys(ASPECT_RATIOS) as RatioName[]).map((key, index) => (
            <Button
                key={`${key}-${index}`}
                size="small"
                startIcon={BUTTON_MAP[key]}
                sx={{
                    fontWeight: cropRatio === key ? 700 : undefined,
                    justifyContent: 'start',
                    width: { sm: 'auto', xs: 'min-content' },
                }}
                onClick={() => handleChangeCropRatio(key)}
            >
                {t(`${key}`)}
            </Button>
        ))}
    </>
);

AssetEditAspectButtons.displayName = 'AssetEditAspectButtons';
