import { Box, IconButton, Stack, Typography } from '@deltasierra/react/components/core';
import { FlipAsset } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { AssetEditAspectButtons } from './AssetEditAspectButtons';
import { AssetEditCanvas } from './AssetEditCanvas';
import { AssetEditFooter } from './AssetEditFooter';
import { AssetEditSlider } from './AssetEditSlider';
import { ROTATION, ZOOM } from './constants';
import { useAssetEditBackend } from './hooks';
import { AssetEditState, DraggableAssetProps, MediaItem } from './types';

export type AssetEditProps = DraggableAssetProps &
    Omit<AssetEditState, 'getAssetOffsetPosition' | 'originalAssetDimensions' | 'setAssetOffset'> &
    ReturnType<typeof useAssetEditBackend> & {
        asset: MediaItem;
        isCropEnabled: boolean;
        onClickCancel: () => void;
        t: Translations<'AssetEdit'>;
    };

const mediumGap = '1.5rem';

// eslint-disable-next-line max-lines-per-function
export const AssetEdit = ({
    asset,
    assetOffset,
    assetRotation,
    assetScale,
    backendResultImageOptions,
    containerRef,
    cropperBounds,
    cropperRef,
    cropRatio,
    draggableAssetRef,
    handleAssetLoad,
    handleAssetRotation,
    handleAssetScale,
    handleChangeCropRatio,
    handleFlipRotation,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleResetAssetRotation,
    handleResetAssetScale,
    isAssetLoaded,
    isCropEnabled,
    isDraggingAsset,
    isLoadingBackend,
    onClickCancel,
    onClickConfirmCropResize,
    t,
}: AssetEditProps): JSX.Element => (
    <Box
        sx={{
            columnGap: mediumGap,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            paddingBottom: mediumGap,
            paddingLeft: mediumGap,
            paddingRight: mediumGap,
        }}
    >
        <Box
            sx={{
                columnGap: mediumGap,
                display: 'flex',
                flexDirection: { sm: 'row', xs: 'column-reverse' },
                flexGrow: 1,
            }}
        >
            <Stack sx={{ flexGrow: 1 }}>
                <AssetEditCanvas
                    asset={asset}
                    assetOffset={assetOffset}
                    assetRotation={assetRotation}
                    assetScale={assetScale}
                    backendResultImageOptions={backendResultImageOptions}
                    containerRef={containerRef}
                    cropRatio={cropRatio}
                    cropperBounds={cropperBounds}
                    cropperRef={cropperRef}
                    draggableAssetRef={draggableAssetRef}
                    handleAssetLoad={handleAssetLoad}
                    handleMouseDown={handleMouseDown}
                    handleMouseMove={handleMouseMove}
                    handleMouseUp={handleMouseUp}
                    isAssetLoaded={isAssetLoaded}
                    isDraggingAsset={isDraggingAsset}
                    t={t}
                />
                <Box
                    sx={{
                        display: 'grid',
                        gridGap: '10%',
                        gridTemplateColumns: { sm: 'auto auto', xs: 'auto' },
                        paddingTop: mediumGap,
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <AssetEditSlider
                            aria-label={t('Zoom')}
                            buttonStep={ZOOM.buttonStep}
                            defaultValue={ZOOM.defaultValue}
                            header={<Typography variant="h6">{t('Zoom')}</Typography>}
                            max={ZOOM.max}
                            min={ZOOM.min}
                            step={ZOOM.step}
                            t={t}
                            value={assetScale}
                            onChange={handleAssetScale}
                            onClickButton={handleResetAssetScale}
                        />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <AssetEditSlider
                            aria-label={t('Rotation')}
                            buttonStep={ROTATION.buttonStep}
                            defaultValue={ROTATION.defaultValue}
                            header={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant="h6">{t('Rotation')}</Typography>
                                    <Box sx={{ columnGap: '0.5rem', display: 'flex' }}>
                                        {/*  TODO: Check special characters like ° work internationalisation */}
                                        <IconButton
                                            aria-label={t('Rotation negative 90°')}
                                            sx={{
                                                color: 'primary.main',
                                            }}
                                            onClick={() =>
                                                handleFlipRotation({
                                                    max: ROTATION.max,
                                                    min: ROTATION.min,
                                                    value: ROTATION.negativeTurn,
                                                })
                                            }
                                        >
                                            <FlipAsset fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            aria-label={t('Rotation positive 90°')}
                                            sx={{
                                                color: 'primary.main',
                                            }}
                                            onClick={() =>
                                                handleFlipRotation({
                                                    max: ROTATION.max,
                                                    min: ROTATION.min,
                                                    value: ROTATION.positiveTurn,
                                                })
                                            }
                                        >
                                            <FlipAsset style={{ transform: 'scaleX(-1)' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            }
                            max={ROTATION.max}
                            min={ROTATION.min}
                            step={ROTATION.step}
                            t={t}
                            value={assetRotation}
                            onChange={handleAssetRotation}
                            onClickButton={handleResetAssetRotation}
                        />
                    </Box>
                </Box>
            </Stack>
            <Stack sx={{ flexDirection: { sm: 'column', xs: 'row' }, flexWrap: 'wrap', minWidth: '14rem' }}>
                <AssetEditAspectButtons cropRatio={cropRatio} handleChangeCropRatio={handleChangeCropRatio} t={t} />
            </Stack>
        </Box>
        <Stack>
            <AssetEditFooter
                isCropEnabled={isCropEnabled && !isLoadingBackend}
                t={t}
                onClickCancel={onClickCancel}
                onClickCrop={onClickConfirmCropResize}
            />
        </Stack>
    </Box>
);

AssetEdit.displayName = 'AssetEdit';
