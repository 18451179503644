'use client';

import {
    AssetLibraryModalContext,
    AssetLibraryModalContextType,
    AssetLibraryOpenAssetModalType,
    MediaItem,
    OnAssetSelected,
    OpenAssetModalOptions,
    ResultAssetFile,
} from '@deltasierra/react/features/asset-library-modal';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AssetLibraryModalContainer } from '../AssetLibraryModalContainer';

export type OnCloseModalType = (assetFile: ResultAssetFile | null) => void;

export const AssetLibraryModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalOptions, setModalOptions] = useState<OpenAssetModalOptions | null>(null);
    const [selectedAsset, setSelectedAsset] = useState<MediaItem | null>(null);

    const openAssetModal = useCallback<AssetLibraryOpenAssetModalType>(
        options => {
            setIsModalOpen(true);
            setModalOptions(options);
            if(options.initialSelectedAsset) {
                setSelectedAsset(options.initialSelectedAsset);
            }
        },
        [setIsModalOpen, setModalOptions],
    );

    const modalContextValue: AssetLibraryModalContextType = useMemo(
        () => ({
            openAssetModal,
        }),
        [openAssetModal],
    );

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);
        setModalOptions(null);
    }, []);

    const onAssetSelectedWithClose = useCallback<OnAssetSelected>(
        asset => {
            if (modalOptions) {
                modalOptions.onAssetSelected(asset);
                onCloseModal();
            }
        },
        [modalOptions, onCloseModal],
    );

    return (
        <AssetLibraryModalContext.Provider value={modalContextValue}>
            {children}
            {isModalOpen && modalOptions && (
                <AssetLibraryModalContainer
                    clientId={modalOptions.clientId}
                    disableUploadsTab={!!modalOptions.disableUploadsTab}
                    initialGalleryState={modalOptions.initialGalleryState ?? null}
                    initialTab={modalOptions.initialTab ?? null}
                    locationId={modalOptions.locationId}
                    preUploadFile={modalOptions.file ?? null}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    uploadDestination={modalOptions.uploadDestination}
                    onAssetSelected={onAssetSelectedWithClose}
                    onCloseModal={onCloseModal}
                />
            )}
        </AssetLibraryModalContext.Provider>
    );
};

AssetLibraryModalProvider.displayName = 'AssetLibraryModalProvider';
