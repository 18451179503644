import { useTranslations } from '@deltasierra/translations/react';
import { AlertContextType } from '@deltasierra/react/components/core';
import { AssetEdit, AssetEditProps } from './AssetEdit';
import { useAssetEdit, useAssetRefs, useDragAsset, useAssetEditBackend } from './hooks';
import { useAssetBoundsValidation } from './hooks/useAssetBoundsValidation';
import { OnCroppedAssetSelected } from './types';

export type AssetEditContainerProps = AlertContextType &
    Pick<AssetEditProps, 'asset'> & {
        onClickCancel: () => void;
    } & { onAssetSelected: OnCroppedAssetSelected };

export const AssetEditContainer = ({
    asset,
    onAssetSelected,
    onClickCancel,
    showAlert,
}: AssetEditContainerProps): JSX.Element => {
    const { containerRef, cropperRef, draggableAssetRef } = useAssetRefs();

    const { isCropEnabled, validateAssetIsWithinCropBounds } = useAssetBoundsValidation({
        cropperRef,
        draggableAssetRef,
    });
    const {
        assetOffset,
        assetRotation,
        assetScale,
        cropperBounds,
        cropRatio,
        handleAssetLoad,
        handleAssetRotation,
        handleAssetScale,
        handleChangeCropRatio,
        handleFlipRotation,
        handleResetAssetRotation,
        handleResetAssetScale,
        isAssetLoaded,
        originalAssetDimensions,
        setAssetOffset,
    } = useAssetEdit({
        containerRef,
        draggableAssetRef,
        validateAssetIsWithinCropBounds,
    });

    const { handleMouseDown, handleMouseMove, handleMouseUp, isDraggingAsset } = useDragAsset({
        draggableAssetRef,
        setAssetOffset,
        validateAssetIsWithinCropBounds,
    });
    const t = useTranslations('AssetEdit');
    const { backendResultImageOptions, isLoadingBackend, onClickConfirmCropResize } = useAssetEditBackend({
        assetId: asset.id,
        assetOffset,
        assetRotation,
        assetScale,
        assetType: asset.__typename,
        cropRatio,
        cropperBounds,
        onAssetSelected,
        originalAssetDimensions,
        showAlert,
        t,
    });

    return (
        <AssetEdit
            asset={asset}
            assetOffset={assetOffset}
            assetRotation={assetRotation}
            assetScale={assetScale}
            backendResultImageOptions={backendResultImageOptions}
            containerRef={containerRef}
            cropRatio={cropRatio}
            cropperBounds={cropperBounds}
            cropperRef={cropperRef}
            draggableAssetRef={draggableAssetRef}
            handleAssetLoad={handleAssetLoad}
            handleAssetRotation={handleAssetRotation}
            handleAssetScale={handleAssetScale}
            handleChangeCropRatio={handleChangeCropRatio}
            handleFlipRotation={handleFlipRotation}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            handleMouseUp={handleMouseUp}
            handleResetAssetRotation={handleResetAssetRotation}
            handleResetAssetScale={handleResetAssetScale}
            isAssetLoaded={isAssetLoaded}
            isCropEnabled={isCropEnabled}
            isDraggingAsset={isDraggingAsset}
            isLoadingBackend={isLoadingBackend}
            t={t}
            onClickCancel={onClickCancel}
            onClickConfirmCropResize={onClickConfirmCropResize}
        />
    );
};

AssetEditContainer.displayName = 'AssetEditContainer';
